<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Modifica rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaRapportino()">
                    <ion-list class="fields">
                        <!-- Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>Data</span>
                                </div>
                                <div class="value">
                                    {{ dateFormat(toEdit.rapportini_data) }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>Cliente</span>
                                </div>
                                <div class="value">
                                    {{
                                        toEdit.customers_company ? toEdit.customers_company : `${toEdit.customers_name} ${selectedCustomer.customers_last_name}`
                                    }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>Commessa</span>
                                </div>
                                <div class="value">
                                    {{ toEdit.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="rapportino.note"
                                        placeholder="Indicare qui eventuali servizi non svolti o motivare gli straordinari"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <button type="submit" class="btn_crea_intervento" :disabled="savingRapportino">
                                Modifica rapportino
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, onMounted } from "vue";

import { openToast } from "@/services/toast";
import { dateFormat } from "@/services/utils";

import apiRapportini from "@/services/rapportini";

import moment from "moment";

export default defineComponent({
    name: "ModificaRapportino",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonIcon,
    },
    setup(props, contenxt) {
        console.log(props);
        const toEdit = { ...props.data };
        //console.log(toEdit);

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const today = moment().format("YYYY-MM-DD");
        const start_hour = moment()
            .subtract(2, "hours")
            .format("YYYY-MM-DD HH:mm");
        const end_hour = moment().format("YYYY-MM-DD HH:mm");

        const rapportino = reactive({
            ora_inizio: toEdit.rapportini_ora_inizio,
            ora_fine: toEdit.rapportini_ora_fine,
            operatori: [userID],
            note: toEdit.rapportini_note,
            immagini: [],
        });

        const successResponse = ref(false);

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            console.log(res);
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Create new rapportino
         */
        const savingRapportino = ref(false);

        async function creaRapportino() {
            savingRapportino.value = true;
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(`${toEdit.rapportini_data} ${rapportino.ora_inizio}`);
            const ora_fine = new Date(`${toEdit.rapportini_data} ${rapportino.ora_fine}`);

            const momentOraInizio = moment(toEdit.rapportini_data + " " + rapportino.ora_inizio);
            const momentOraFine = moment(toEdit.rapportini_data + " " + rapportino.ora_fine);

            // 23/02/2024 - Invalid date, tk #10577
            if (!momentOraInizio.isValid() || !momentOraFine.isValid()) {
                savingRapportino.value = false;
                openToast("Non è stato possibile riconoscere uno dei due orari inseriti", "toast_danger");
                return;
            } else {
                // - Entrambi i momenti sono validi
            }

            if (momentOraInizio.isAfter(momentOraFine)) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            } else {
                // Ora inizio è prima o uguale a ora fine
            }

            /*if (ora_inizio > ora_fine) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }

            const ora_inizio_formatted = moment(ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(ora_fine).format("HH:mm");
            */
            // Correggo avendo usato moment
            const ora_inizio_formatted = momentOraInizio.format("HH:mm");
            const ora_fine_formatted = momentOraFine.format("HH:mm");

            const data = new FormData();
            data.append("rapportini_ora_inizio", ora_inizio_formatted);
            data.append("rapportini_ora_fine", ora_fine_formatted);
            if (rapportino.note) {
                data.append("rapportini_note", rapportino.note);
            }

            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            try {
                const response = await apiRapportini.editRapportino(data, toEdit.rapportini_id);
                console.log(response);
                if (response.data.status === 8) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                console.error(error);
                savingRapportino.value = false;
                openToast("Errore durante la modifica del rapportino", "toast_danger");
            } finally {
                setTimeout(() => {
                    savingRapportino.value = false;
                }, 1000);
            }
        }

        onMounted(() => {
            //loadServiziCommessa();
            //loadCustomer();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            creaRapportino,
            savingRapportino,
            rapportino,
            //operatori,
            dateFormat,
            //MODIFICA RAPPORTINO
            toEdit,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 6px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
ion-button {
    --color: #ffffff;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(62, 160, 208);
    color: #ffffff;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_allega_foto {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #06b6d4;
    margin: 24px 0;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}

.info_aggiuntive {
    font-size: 14px;
}
</style>
